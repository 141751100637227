import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CCenterSlider,
  CFixedImg,
  CDefinition,
  CBtnList,
  LGuestRoom,
  LPickup,
  LPlan,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: <>luxury twin</>,
            ja: <>ラグジュアリーツイン</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/guestroom/luxurytwin/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/guestroom/luxurytwin/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宿泊',
                path: '/stay/',
              },
              {
                label: '客室一覧',
                path: '/stay/guestroom/',
              },
            ],
            current: {
              label: 'ラグジュアリーツイン',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect02">
        <section className="u_mbExLarge">
          <LWrap exClass="u_mbExLarge">
            <h2 className="c_headingLv2">
              大窓から眺める季節の景色
              <br />
              開放感溢れるツインルーム
            </h2>
            <p className="c_sectLead">
              大きな窓が特徴的な開放感あるお部屋です。
              <br />
              ガーデンを臨むバルコニーで四季折々のリゾートステイをおたのしみください。
            </p>
          </LWrap>
          <CCenterSlider
            data={[
              {
                img: {
                  src: '/assets/images/stay/guestroom/luxurytwin/img_room.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/guestroom/luxurytwin/img_room02.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/guestroom/luxurytwin/img_room03.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/guestroom/luxurytwin/img_room04.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/stay/guestroom/luxurytwin/img_room05.png',
                  alt: '',
                },
              },
            ]}
          />
          <LWrap>
            <CFixedImg
              width={746}
              img={{
                src: '/assets/images/stay/guestroom/luxurytwin/img_drawing.png',
                alt: '部屋の見取り図',
              }}
            />
            <CDefinition
              exClass="u_mb30"
              data={[
                {
                  title: <>広さ</>,
                  text: <>37㎡</>,
                },
                {
                  title: <>人数</>,
                  text: <>1～3名</>,
                },
                {
                  title: <>ベッドサイズ</>,
                  text: <>1200×2000mm（×2台）</>,
                },
                {
                  title: <>エキストラベッド</>,
                  text: <>可</>,
                },
                {
                  title: <>部屋数</>,
                  text: <>26室（禁煙）</>,
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: <>スペック</>,
                en: <>SPEC</>,
              }}
            />
            <CDefinition
              exClass="u_mb100 u_mb80_sp"
              data={[
                {
                  title: <>チェックイン</>,
                  text: <> 15:00</>,
                },
                {
                  title: <>チェックアウト</>,
                  text: <>11:00</>,
                },
                {
                  title: <>客室設備・備品</>,
                  text: (
                    <>
                      エアウィーヴマットレスパッド / 空気洗浄加湿器 / 空調設備 /
                      ウォッシュレット / セーフティーボックス /
                      シューシャインクロス / シューホーン / 洋服ブラシ /
                      ドライヤー / 電気ポット / 紅茶 / 緑茶 / ルームウェア
                    </>
                  ),
                },
                {
                  title: <>アメニティ</>,
                  text: (
                    <>
                      シャンプー / コンディショナー / ハブラシ / ボディソープ /
                      ハンドソープ / シェーバー / ヘアブラシ / 使い捨てスリッパ
                      / コットン・綿棒セット
                      <ul className="c_noteList">
                        <li>
                          シャワーキャップ、スキンケアセット、ボディタオルはフロントにてご用意しております
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  title: <>通信環境</>,
                  text: <>Wi-Fi 接続無料 ／ 電話回線／ TV</>,
                },
                {
                  title: <>貸出備品</>,
                  text: (
                    <>
                      アイロン / ズボンプレッサー / ブルーレイプレイヤー /
                      電気スタンド / 毛布 / そば殻枕 / アイスノン /
                      お子様用ルームウェア（サイズ約130cm）※数量限定 /
                      ベビー布団 / バスチェア / バスピロー /
                      バスタブ用滑り止めマット / バスタブ用手すり / ベッドガード
                      / 延長コード / 体重計 / ソーイングセット / 爪切り /
                      囲碁・将棋 / お子様用おもちゃ（絵本・ジェンガなど）
                      <br />
                      ※ベッドガードの対応年齢は、生後18か月～60か月（満5歳）までとなります。18か月未満の乳児にはご使用いただけませんので、あらかじめご了承ください。
                    </>
                  ),
                },
                {
                  title: <>有料サービス</>,
                  text: (
                    <>
                      <ul className="c_circleList">
                        <li>ランドリーサービス</li>
                        <li>新聞</li>
                        <li>宅配便</li>
                        <li>マッサージ（20:00～24:00まで）</li>
                      </ul>
                    </>
                  ),
                },
              ]}
            />
            <CBtnList
              data={[
                {
                  label: '客室一覧をみる',
                  link: {
                    href: '/stay/guestroom/',
                  },
                  icon: 'arrow',
                  color: 'borderTheme',
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <LGuestRoom />
      <LPickup />
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
